<template>
  <login-layout
    @submit="processStep"
    :errors="errors"
    @errors="errors = $event"
    :info2="info2"
    :info2callback="info2callback"
  >
    <div class="login-form">
      <h2 class="title">
        {{ $t(resolveTitle()) }}
      </h2>
      <p
        v-if="!isBack"
        class="text"
      >
        {{ $t(resolveNote()) }}
      </p>

      <username-input
        ref="username"
        v-model="username"
        :class="{ invalid: errors[0] && errors[0].tKey !== 'verify_humanity' }"
        :edit-mode="step === 'username'"
        :label="$t(account === 'standard' ? 'username' : 'email') | sentence"
        @edit-start="onEditStart"
        @input="errors.length = 0; legacyLoginUrl = ''"
        :autofocus="step === 'username'"
        class="username"
        name="username"
        required
      />
      <password-input
        ref="password"
        v-if="!showWebauthnLogin"
        :class="{ invalid: !!errors.length, shrinked: step !== 'password' }"
        :placeholder="$t('password')"
        v-model="password"
        @input="errors.length = 0"
        :autofocus="step === 'password'"
        name="password"
        class="password"
        required
      />
      <recaptcha-policy
        v-if="!showWebauthnLogin || loading"
        class="captcha"
      />
    </div>

    <template slot="lower">
      <div
        v-if="!!legacyLoginUrl"
        class="message a24-legacy-notice"
      >
        <img
          :src="iconInfo"
          alt="info icon"
        >
        <div>
          <p>
            {{ $t('info.user_is_legacy.active24.text') }}
          </p>
          <a
              :href="legacyLoginUrl"
              class="btn btn-primary btn-sm right-button legacy-login"
              rel="nofollow"
          >
            {{ $t('info.user_is_legacy.active24.button') }}
          </a>
        </div>
      </div>
      <link-and-button
        v-if="!showWebauthnLogin || loading"
        :link="resolveLink()"
        :button="{ tKey: btnText }"
        :disabled="loading || !!legacyLoginUrl"
        :loading="loading"
      />
      <webauthn
        v-if="showWebauthnLogin && !loading"
        :animate-success="animateWebauthn"
        @authenticate-clicked="webauthnLogin"
        @alternative-process="onStandardLogin()"
        :alternative-process-title="$t('webauthn.use_standard_login')"
        :try-again-title="$t('webauthn.try_again_passwordless')"
        :auth-type="authType"
      />
      <template v-if="usecase !== 'restore'">
        <template v-if="showBankId()">
          <hr-delimiter :text="$t('or')" />
          <bank-id-button />
        </template>
        <template v-if="showMojeId()">
          <hr-delimiter :text="$t('or')" />
          <moje-id-login-button />
          <error-note
            v-if="!!mojeIdErrors.length"
            :error-list="mojeIdErrors"
          />
        </template>
        <template v-if="site === 'admin'">
          <hr-delimiter :text="$t('or')" />
          <account-switch :account="account" />
        </template>
      </template>
    </template>
  </login-layout>
</template>

<script>
import {mapState} from "vuex";

import env from "env";
import supportIcon from "client/images/Icon_support.svg";
import iconInfo from "client/images/Icon_Notice.svg";
import store from "../../store";
import {router, routerUtils} from "../../router";
import {catch2fa, mapFilters} from "../../utilities";
import ssoService from "../../services/sso";
import errorService from "../../services/error";
import webauthnService from "client/app/services/webauthn";
import PasswordInput from "../PasswordInput";
import RecaptchaPolicy from "../RecaptchaPolicy";
import LinkAndButton from "../LinkAndButton";
import HrDelimiter from "../HrDelimiter";
import AccountSwitch from "./AccountSwitch";
import UsernameInput from "./UsernameInput";
import LoginLayout from "./LoginLayout";
import Webauthn from "../Webauthn";
import config from '../../services/config';
import BankIdButton from "../bankID/BankIDButton";
import MojeIdLoginButton from "../mojeID/MojeIdLoginButton.vue";
import ErrorNote from "../ErrorNote.vue";
import xss from "xss";

export default {
  name: "Login",
  components: {
    ErrorNote,
    MojeIdLoginButton,
    BankIdButton,
    Webauthn,
    LoginLayout,
    PasswordInput,
    UsernameInput,
    RecaptchaPolicy,
    LinkAndButton,
    HrDelimiter,
    AccountSwitch,
  },
  data() {
    const query = store.state.route.query;
    return {
      step: "username", // process steps: username, password
      username: store.state.login || query.login || query.username || "",
      webauthnLoginEnabled: store.state.webauthnLoginEnabled,
      animateWebauthn: false,
      showStandardLogin: true,
      password: "",
      expose: false, // expose password
      errors: [],
      loading: false,
      supportIcon,
      iconInfo,
      isBack: !!store.state.login,
      info2: "",
      authType: webauthnService.PASSWORDLESS,
      mojeIdErrors: [],
      legacyLoginUrl: "",
    };
  },
  computed: {
    ...mapState([
      "site",
      "redirectTo",
      "login",
      "rememberedUsers",
      "activeLogin",
      "account",
    ]),
    usecase() {
      // usecase: restore, newpass, login
      return this.$route.query.usecase || "login";
    },
    btnText() {
      return {
        login: "next",
        password: "login.imperative",
        restore: "next",
      }[this.usecase];
    },
    showWebauthnLogin() {
      return (
        this.step !== "username" &&
        !this.showStandardLogin &&
        this.webauthnLoginEnabled
      );
    },
  },
  watch: {
    step() {
      this.clearMessages();
    },
    account() {
      if (this.step === "password") {
        this.$store.commit("updateState", { login: null });
        this.setStep("username");
      } else if (this.$store.state.login) {
        this.setStep("password");
      }
      this.clearMessages();
    },
  },
  created() {
    const urlAccount = this.$route.query.account;
    if (urlAccount && urlAccount !== this.account) {
      // sync store value according url, as url has precedence
      this.$store.commit("updateState", { account: urlAccount });
    }

    if (store.state.login !== undefined) {
      this.rememberedUsers.map(user => {
        if (user.login === store.state.login) {
          this.setWebauthnLogin(user.webauthnLoginEnabled);
        }
      });
    }
  },
  mounted() {
    // set step
    if (this.usecase === "restore" || this.usecase === "newpass") {
      this.setStep("username");
    } else {
      this.setStep(this.login ? "password" : "username");
    }

    this.lookupUser();
    this.collectMojeIdErrors();
  },
  destroyed() {
    this.clearMessages(true);
  },
  methods: {
    xss,
    ...mapFilters(["sentence"]),
    clearMessages(clearQuery) {
      this.info2 = "";
      this.errors.length = 0;
      if (clearQuery) {
        routerUtils.removeQueryParam("step", "info", "info2");
      }
    },
    setWebauthnLogin(state) {
      this.webauthnLoginEnabled = !!state;
      this.showStandardLogin = !state;
      this.$store.commit("updateState", { webauthnLoginEnabled: !!state });
    },
    lookupUser() {
      if (this.username) {
        this.loading = true;

        ssoService
          .checkName(this.username, this.account)
          .then(resp => {
            this.$store.commit("updateState", { isUserSubAdmin: !!resp.isUserRoleSubadmin });
            this.setWebauthnLogin(resp.webauthnLogin);
            this.loading = false;
            this.legacyLoginUrl = "";

            if(resp.links) {
              config.company.links = resp.links
            }

            this.$store.commit("updateState", { login: this.username });
            if (this.usecase === "restore") {
              router.push({
                path: "/recovery/password",
                query: { username: this.username },
              });
            } else if (this.usecase === "newpass") {
              router.push({
                name: "new-password",
                query: { username: this.username },
              });
            } else if (resp.loginUrl) {
              // handle LegacyActive24User case
              this.legacyLoginUrl = this.resolveLegacyLink(resp);
            } else {
              this.setStep("password");
            }
          })
          .catch(err => {
            this.loading = false;
            const type = err && errorService.parseError(err).type;
            if (type === "/user/is/email") {
              this.$store.commit("updateState", { login: this.username });
              this.info2 = "info.user_is_email";
            } else if (type === "/user/is/standard") {
              this.$store.commit("updateState", { login: this.username });
              this.info2 = "info.user_is_standard";
            } else if (type === "/user/is/legacy-active24") {
              this.errors = errorService.processError(err);
            } else {
              this.errors = errorService.processError(err);
            }
          });
      }
    },
    checkWebauthnLoginEnabled() {
      ssoService.checkName(this.username, this.account).then(resp => {
        this.setWebauthnLogin(resp.webauthnLogin);
      });
    },
    async webauthnLogin() {
      if (webauthnService.supports()) {
        const data = await webauthnService.assertion(store.state.login);

        ssoService
          .login({ login: store.state.login, client: store.state.site || null, ...data })
          .then(response => {
            this.animateWebauthn = true;

            // Wait for fingerprint animation to complete
            setTimeout(() => {
              this.redirect(response.redirectTo);
            }, 600);
          })
          .catch(err => {
            const type = errorService.parseError(err).type;

            if (type === "/user/client-forbidden") {
              // catch "User is not allowed to access client" case
              router.push({
                name: "forbidden",
              });
            }

            if (catch2fa(err)) {
              router.push("code/2step");
              return;
            }

            this.errors = errorService.processError(err);
          });
      }
    },
    processStep(step) {
      if (!step || typeof step !== "string") {
        step = this.step;
      }

      this.clearMessages();

      // autocorrect inputs
      this.username = this.username.trim();

      // validation
      // assuming step and field names are same!
      if (!this[this.step]) {
        // value for step is falsy (empty string or undefined)
        let fieldName = "password";
        if (this.step === "username") {
          fieldName = this.account === "standard" ? "username" : "email";
        }
        return this.errors.push(this.getRequiredError(fieldName));
      }

      // assuming step and field names are same!
      switch (step) {
        case "username":
          this.lookupUser();
          break;
        case "password":
          this.loading = true;
          ssoService
            .login({
              login: this.username,
              password: this.password,
              userType: this.account,
              client: this.site || null,
            })
            .then(data => {
              this.loading = false;
              this.redirect(data.redirectTo);
            })
            .catch(err => {
              this.loading = false;

              if (err) {
                const type = errorService.parseError(err).type;

                switch (type) {
                  case "/user/client-forbidden":
                    // catch "User is not allowed to access client" case
                    router.push({
                      name: "forbidden",
                    });
                    break;

                  case "/user/terms-not-accepted":
                    // catch "Terms not accepted" case
                    this.$store.commit("updateState", { pz: this.password }); // pass pz via store
                    router.push({
                      name: "terms",
                    });
                    break;
                }

                if (catch2fa(err)) {
                  router.push("code/2step")
                  return;
                }
              }
              this.errors = errorService.processError(err);
            });
          break;
      }
    },
    redirect(url) {
      if (!env.isBrowser) {
        return;
      }
      this.loading = true;
      window.location = url || store.state.company.defaultRedirectUrl;
    },
    setStep(step) {
      this.step = step;
      // assuming step and refs have same name!
      this.focus(step);
    },
    focus(refKey) {
      setTimeout(() => {
        const input = this.$refs[refKey];
        if (input) {
          input.focus();
        }
      }, 0);
    },
    onEditStart() {
      this.$store.commit("updateState", { login: null });
      this.setStep("username");
    },
    onStandardLogin() {
      this.loading = false;
      this.showStandardLogin = true;
    },
    resolveTitle() {
      if (this.usecase === "restore" || this.usecase === "newpass") {
        return "password_recovery";
      } else if (this.isBack) {
        return "welcome_back";
      } else {
        if (this.site === "admin") {
          return this.account === "standard" ? "login.admin" : "login.email";
        }
        return "login";
      }
    },
    resolveNote() {
      if (this.usecase === "restore" || this.usecase === "newpass") {
        return "restore.note";
      }
      if (this.site === "admin") {
        return this.account === "standard"
          ? "login.admin.note"
          : "login.email.note";
      }
      return "login.admin.note";
    },
    resolveLink() {
      if (this.step === "password" || this.step === "username") {
        return {
          icon: {
            src: supportIcon,
            alt: "icon support",
          },
          route: {
            path: "recovery/" + this.step,
            query: this.step === "password" ? { username: this.username } : {},
          },
          tKey: { username: "forgotten_login", password: "forgotten_password" }[
            this.step
          ],
        };
      }
      // else
      return null;
    },
    info2callback() {
      const account = this.account === "email" ? "standard" : "email";
      this.$store.commit("updateState", { account });
      this.$router.push({
        name: "login",
        query: { account },
      });
      this.clearMessages();
    },
    getRequiredError(fieldName) {
      return {
        tKey: "field_is_required",
        tArgs: {
          field: () => '"' + this.sentence(this.$t(fieldName)) + '"',
        },
      };
    },
    showBankId() {
      // Swedish users only.
      // Backwards compatible String.startsWith()
      return config.company.id.lastIndexOf('se-', 0) === 0;
    },
    showMojeId() {
      const allowedSites = ["admin", "cart"];
      //change condition on release
      return config.mojeId !== undefined && allowedSites.includes(this.site);
    },
    collectMojeIdErrors() {
      if (this.$route.query['moje-id-error'] !== undefined) {
        this.mojeIdErrors.push({
          tKey: `moje_id.error.${this.$route.query['moje-id-error']}`,
          tArgs: {
            legacyPlatformLinkStart: () => `<a href="${this.$route.query['legacy-platform-url']}">`,
            legacyPlatformLinkEnd: () => `</a>`,
            },
        })
      }
    },
    resolveLegacyLink(resp) {
      let url = resp.loginUrl;

      switch (this.site) {
        case 'cart':
          url = resp.orderUrl ?? url;
          break;

        case 'admin':
        case 'helpdesk':
          url = resp.webadminUrl ?? url;
          break;
      }

      return url.toString();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.loading) {
      // prevent navigation
      next(false);
    } else {
      this.clearMessages(true);
      next();
    }
  },
};
</script>

<style lang="scss">
@import "bootstrap/scss/utilities/flex";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/spinners";
</style>

<style lang="scss" scoped>
.login-form {
  margin-bottom: 1rem;
}

.username {
  ::v-deep .form-label-group {
    margin-top: $h2-space-c2;
  }
}

.password {
  ::v-deep .form-label-group {
    margin-top: calc(1rem - (#{$border-width} * 2));
  }
}

.captcha {
  margin-top: 0.5rem;
  font-size: 0.8125rem;
}

.shrinked {
  display: block;
  overflow: hidden;
  height: 0;
}

.content ::v-deep button#bank-id-login {
  width: 100%;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.message.a24-legacy-notice {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem .5rem;

  .btn {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  img {
    width: 3rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  p {
    margin-bottom: 0.5rem;
  }
}
</style>
