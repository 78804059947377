export default {
	// app frame
	"footer.part1": "You can always have a look at our",
	"footer.part2": "knowledge database",
	"footer.part3": "for more information. If you can't find the answer to your question, please contact our support.",
	// outdated modal
	"out-modal_title": "Sorry, your browser<br>is not up to date",
	"out-modal_text": "Please upgrade it to the newest version or try installing a different browser.",
	"out-modal_delimiter-text": "We can help",
	// cookie banner
	"cookie_banner.title": "Policy on Use of Cookie Files&nbsp;&#x1F36A;",
	"cookie_banner.text": "To make this tool work 100%, we use cookie files. We also use cookie files to analyse the usage of this tool, but with no processing of any personal data.",
	"cookie_banner.link": "More information",
	"close": "Close",
	// login
	"first_time.message": "Welcome! Here you can login to your account administration and/or email settings.",
	"welcome_back": "Welcome back",
	"forbidden.title": "You don’t have permission.",
	"forbidden.crumb": "Forbidden access",
	"forbidden.message.web": "Oops, your account does not have permission to log in to the application. To continue, choose one of following options.",
	"forbidden.message.web_subadmin": "You’re currently logged into a subadmin account used to manage the services assigned in Webadmin. Log into your admin account right here.",
	"forbidden.message.web_subadmin_link": "Go to Webadmin",
	"forbidden.message.other": "Oops, your account does not have permission to log in to the application. To continue, choose a different user.",
	"forbidden.continue_link": "Continue as an unregistered user",
	"forbidden.button": "Change user",
	"info.pass_changed": "Success! Your password has been changed. Time to sign in using your new password!",
	"info.user_is_email.title": "Need to access your email settings?",
	"info.user_is_email.text1": "It seems that you are trying to login to manage your email settings. ",
	"info.user_is_email.link": "Click here to log in",
	"info.user_is_email.text2": ".",
	"info.user_is_legacy.active24.text": "We found this user on our legacy platform, click on the button below to continue.",
	"info.user_is_legacy.active24.button": "Go to Legacy Active24 Login",
	"info.user_is_standard.title": "Need to access your admin account?",
	"info.user_is_standard.text1": "It seems that you are trying to login to your admin account. ",
	"info.user_is_standard.link": "Click here to log in",
	"info.user_is_standard.text2": ".",
	"login": "Login",
	"login.admin": "Login to admin account",
	"login.admin.note": "In order to login, please fill in login details chosen during registration.",
	"login.email": "E-mail setting login",
	"login.email.note": "In order to login, please fill in login details you received from your administrator.",
	"login.imperative": "log in",
	"restore.note": "To restore the password, we will need your user login",
	"username": "Login",
	"password": "Password",
	"forgotten_login": "Forgot your login?",
	"forgotten_password": "Forgot your password?",
	"next": "continue",
	"have_trouble": "Need any help?",
	"help": "help",
	"terms": "terms",
	"privacy": "privacy",
	"change": "change",
	"field_is_required": "The field {field} is required.",
	"loading": "loading...",
	"account.go_email.title": "E-mail settings",
	"account.go_email.link": "I want to set up my email",
	"account.go_standard.title": "Login to your Webadmin account",
	"account.go_standard.text": "To log in, enter the user login chosen at registration.",
	"account.go_standard.link": "Click here to log in",
	// terms
	"confirm_terms": "confirm TOS",
	"welcome_first": "Welcome for the First time",
	"terms.note.part1": "Please read WebSupport's ",
	"terms.note.part2": "terms of service",
	"terms.note.part3": ". Your agreement is required if you wish to continue to the application.",
	"terms.checkbox_label": "I accept the terms and use",
	"go_to_app": "go to app",
	// list
	"list_select.title": "Choose a user",
	"list_select.message": "Enter the user login that you chose during registration",
	"list_confirm.title": "Continue as",
	"list_confirm.message": "Choose the user that you want to log in as",
	"another_user": "Another user",
	"currently_logged": "Currently logged in",
	"continue": "Continue",
	"or": "or",
	"user_type_subadmin": "Subadmin",
	"user_type_email": "Your e-mail account",
	"forbidden_info": "You are not allowed to log in as this user, because it does not have permission to enter the application.",
	// recovery
	"recovery.feature_not_available": "In order to recover your login credentials, please contact your email administrator.",
	"email_recovery": "e-mail recovery",
	"username_recovery": "login recovery",
	"password_recovery": "password recovery",
	"email": "e-mail",
	"SMS": "SMS",
	"phone-number": "Phone number",
	"email-placeholder": "yourname@example.com",
	"phone-placeholder": "912 543 876",
	"login-placeholder": "login",
	"restore_login": "restore login",
	"restore_password": "restore password",
	"no_results_found": "no results found",
	"one_of_required": "One of the fields {field1} and {field2} is required.",
	"error.invalid.phone": "Phone number contains invalid characters.",
	// message
	"email_sent.message.part1": "We've sent an email to your <b>address</b>",
	"email_sent.message.part2": ". If you don't receive the email within the next 5-10 minutes, try to send it manually or contact our support team.",
	"email_sent.title": "We've sent an email to your inbox",
	"email_sent.again": "Send email again",
	"email_sent.again.success": "The email was successfully sent",
	"sms_sent.message.part1": "We've sent an SMS to your <b>phone</b>",
	"sms_sent.message.part2": ". If you don't receive the SMS within the next 5-10 minutes, try to send it manually or contact our support team.",
	"sms_sent.title": "We've sent you an SMS",
	"sms_sent.again": "Send SMS again",
	"sms_sent.again.success": "The SMS was successfully sent",
	"back_to_login": "Go back to Log in",
	"and": "and",
	"more_emails.plural": " | another address | {n} other addresses",
	"missing_params": "the service is missing parameters",
	// code
	"recover_sent.crumb1": "SMS code",
	"recover_sent.crumb2": "Set a new password",
	"recover_sent.title": "You have received an SMS code",
	"recover_sent.message.part1": "We've sent a 6-digit SMS code to your phone",
	"recover_sent.message.part2": ". Please fill the following field.",
	"recover_sent.submit": "confirm",
	"2step_sent.crumb1": "Login",
	"2step_sent.crumb2": "Verification",
	"2step_sent.title": "Verification",
	"2step_sent.message.part1": "Enter code from your mobile app",
	"2step_sent.message.part2": ". Please fill the following field.",
	"2step_sent.submit": "Verify",
	"code.placeholder": "confirmation code",
	"code.error_length": "The code needs to be 6 digits long",
	"code.required": "The code is required",
	// webauthn 2fa
	"webauthn2fa.use_totp_instead": "Use one-time password authentication (TOTP)",
	"webauthn2fa.use_webauthn_instead": "Use biometric / HW authentication",
	"webauthn2fa.use_webauthn_desc": "Authenticate with your biometric / hardware authenticator",
	"webauthn2fa.not_supported": "Your browser unfortunately does not support biometric / hardware authentication.",
	// webauthn login
	"webauthn.title": "Authenticate your biometric or hardware key",
	"webauthn.continue": "Please run the authentication using your chosen biometric or hardware key.",
	"webauthn.use_standard_login": "Use standard password login",
	"webauthn.try_again_passwordless": "Try passwordless again",
	"webauthn.try_again_two_factor": "Please try again",
	"webauthn.not_supported": "Your browser unfortunately does not support passwordless login.",
	"webauthn.authenticate": "Authenticate",
	// password
	"recover_pass.crumb1": "SMS code",
	"recover_pass.crumb1email": "E-mail",
	"recover_pass.crumb2": "Set a new password",
	"pass.title": "Set a new password",
	"pass.rule1": "a minimum of 8 characters",
	"pass.rule2": "lowercase and uppercase letters",
	"pass.rule3": "at least one digit",
	"pass.rule4": "at least one special character",
	"pass.placeholder1": "Choose new password",
	"pass.placeholder2": "Confirm new password",
	"pass.submit": "change password",
	"pass.dont_match": "password fields don't match",
	"pass.invalid": "wrong password format",
	"pass.required": "password is a required field",
	"pass.confirm.required": "password confirmation is a required field",
	// password input
	"capslock.line1": "Caps Lock",
	"capslock.line2": "is on",
	// page 404
	"page404.title": "<b>404</b> You might have gotten lost",
	"page404.message": "Did you get lost?",
	"page404.actionBtn": "Back to the beginning",
	// recaptcha
	"recaptcha_policy.part1": "This page is protected by Google’s reCAPTCHA system with its",
	"recaptcha_policy.part2": "and",
	"recaptcha_policy.part3": ".",
	"recaptcha_policy.policy_link": "Privacy Policy",
	"recaptcha_policy.terms_link": "Terms of Service",
	// error note
	"unknown_error": "Unknown error.",
	"offline_error": "Oops! The operation failed. Please check your internet connection.",
	"verify_humanity": "Please prove that you are human. Enter the right information through the reCaptcha system.",
	// api errors
	"error_api/user/inactive": "The user was deactivated.",
	"error_api/user/not-found": "Invalid login.",
	"error_api/user/unauthorized": "The combination of login and password is incorrect.",
	"error_api/user/invalid-webauthn-login": "Passwordless login is not set.",
	"error_api/user/invalid-webauthn-2fa": "Passwordless two factor authentication is not set.",
	"error_api/user/terms-not-accepted": "The terms haven't been accepted.",
	"error_api/user/client-forbidden": "The user does not have permission to enter the application.",
	"error_api/recovery/code": "You've entered a wrong or expired code.",
	"error_api/2fa/code": "You've entered a wrong or expired code.",
	"error_api/user/ratelimit": "The maximum request limit has been reached.",
	"error_api/user/legacy-active24": "Please login via the Legacy Active24 login page",
	// general api errors
	"error_status_401": "You don't have the required permissions.",
	"error_status_403": "Access denied.",
	"error_status_404": "Source not found.",
	"error_status_407": "Please login via the Legacy Active24 login page.",
	"error_status_408": "Connection timeout.",
	"error_status_500": "Uh oh, something went wrong. Please contact our support.",
    // bank id
    "bank_id.title": "Log in via BankID",
    "bank_id.message": "Would you like to identify yourself or sign with a BankID on another device...",
    "bank_id.message_number": "...with a BankID on this device?",
    "bank_id.login_with": "Login using BankID app",
    "bank_id.input_placeholder": "Your personal number (not required)",
    "bank_id.personal_number_incorrect": "Personal number is incorrect, please use a valid format (e.g. YYMMDD-XXXX)",
    "bank_id.show_qr_code": "Reveal BankID QR",
    "bank_id.pick_account": "Pick account to login to",
    "bank_id.try_again": "Try again",
    "bank_id.logging_in": "Logging in....",
    "bank_id.back_to_login": "Back to login",
    "bank_id.number_not_assigned": "Your personal number is not assigned to any account. Please log in using another method and add it in the security settings.",

    "bank_id.message.RFA1": "Start your BankID app.",
    "bank_id.message.RFA2": "The BankID app is not installed. Please contact your internet bank.",
    "bank_id.message.RFA3": "Action cancelled. Please try again.",
    "bank_id.message.RFA4": "An identification or signing for this personal number is already started. Please try again.",
    "bank_id.message.RFA5": "Internal error. Please try again.",
    "bank_id.message.RFA6": "Action cancelled.",
    "bank_id.message.RFA8": "The BankID app is not responding. Please check that the program is started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. Try again.",
    "bank_id.message.RFA9": "Enter your security code in the BankID app and select Identify or Sign.",
    "bank_id.message.RFA13": "Trying to start your BankID app.",
    "bank_id.message.RFA14A": "Searching for BankID:s, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can order one from your internet bank. If you have a BankID on another device you can start the BankID app on that device.",
    "bank_id.message.RFA14B": "Searching for BankID:s, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this device. If you don’t have a BankID you can order one from your internet bank. If you have a BankID on another device you can start the BankID app on that device.",
    "bank_id.message.RFA15A": "Searching for BankID:s, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can order one from your internet bank.",
    "bank_id.message.RFA15B": "Searching for BankID:s, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this device. If you don’t have a BankID you can order one from your internet bank",
    "bank_id.message.RFA16": "The BankID you are trying to use is revoked or too old. Please use another BankID or order a new one from your internet bank.",
    "bank_id.message.RFA17A": "The BankID app couldn’t be found on your computer or mobile device. Please install it and order a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.",
    "bank_id.message.RFA17B": "Failed to scan the QR code. Start the BankID app and scan the QR code. Check that the BankID app is up to date. If you don't have the BankID app, you need to install it and order a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.",
    "bank_id.message.RFA18": "Start the BankID app",
    "bank_id.message.RFA19": "Would you like to identify yourself or sign with a BankID on this computer or with a Mobile BankID?",
    "bank_id.message.RFA20": "Would you like to identify yourself or sign with a BankID on this device or with a BankID on another device?",
    "bank_id.message.RFA21": "Identification or signing in progress.",
    "bank_id.message.RFA22": "Unknown error. Please try again.",
	//moje id
	"moje_id.error.invalid-user": "There is no account connected to this MojeId",
	"moje_id.error.invalid-user-cz-a24": "There is no account connected to this MojeId. Are you trying to log in to the {legacyPlatformLinkStart}legacy platform{legacyPlatformLinkEnd}?",
	"moje_id.error.invalid-token": "Unexpected error occurred, please try again",
	"moje_id.error.invalid-data": "You need to authorize all required data",
}
