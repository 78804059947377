var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ws-button",
    {
      staticClass: "full-button theme-bg uppercase",
      attrs: { id: "bank-id-login", size: "lg", variant: "primary" },
      on: { click: _vm.login }
    },
    [
      _c("img", {
        staticClass: "mr-18",
        attrs: { src: _vm.bankIdIcon, alt: "Bank ID" }
      }),
      _vm._v("\n  " + _vm._s(_vm.label || _vm.$t("bank_id.login_with")) + "\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }