<template>
  <ws-button
    id="bank-id-login"
    @click="login"
    class="full-button theme-bg uppercase"
    size="lg"
    variant="primary"
  >
    <img
      :src="bankIdIcon"
      alt="Bank ID"
      class="mr-18"
    />
    {{ label || $t('bank_id.login_with') }}
  </ws-button>
</template>

<script>
import ssoService from "../../services/sso";
import {router} from "client/app/router";
import WsButton from "WS_UIkit/WsButton.vue";
import bankIdIcon from "client/images/bankid.svg";
import {isMobileDevice} from "client/app/utilities";
import errorService from "client/app/services/error";

export default {
  name: "BankIDButton",
  components: {WsButton},
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bankIdIcon,
      ssoService,
      errors: [],
    };
  },
  computed: {},
  created() {
  },
  methods: {
    login() {
      if (isMobileDevice()) {

        ssoService
            .bankIdAuth({onDeviceAppAuth: true})
            .then(async ({data}) => {
              const orderRef = data.orderRef;
              const token = data.autoStartToken;
              const url = `${window.location.origin}/bankid-login/${orderRef}`;
              window.location = `https://app.bankid.com/?autostarttoken=${token}&redirect=${url}`;

              await this.$nextTick();

              router.push({
                path: "/bankid",
              });
            })
            .catch(err => {
              if (err) {
                this.errors = errorService.processError(err);
              }
            });

      } else {
        router.push({
          path: "/bankid",
          query: {username: this.username},
        });
      }
    },
  },
};
</script>

<style lang="scss">
button#bank-id-login {
  display: block;
  font-family: $font-family-secondary !important;
  text-align: center;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
  font-size: 1.375rem;
  line-height: 1.25rem;

  .mr-18 {
    margin-right: 18px;
  }
}
</style>
